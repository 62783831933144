import React, { useEffect } from "react";
import Topbar from "../../components/Topbar/Topbar";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer";

import { DatePicker } from "antd";

import { Space, Table, Tag } from "antd";
import type { ColumnsType } from "antd/es/table";
import { CommonApi } from "../../apis/commonApi";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

import logo from "../../assets/images/logo.png";

import { Select } from "antd";
import Loader from "../../components/Loader";
import { HiRefresh } from "react-icons/hi";

const handleChange = (value: string) => {
  console.log(`selected ${value}`);
};

dayjs.extend(customParseFormat);

const { RangePicker } = DatePicker;

interface DataType {
  key: string;
  name: string;
  age: number;
  address: string;
  tags: string[];
}

const orderState = ["new", "completed"];

const Order = () => {
  const [show, setShow] = React.useState("new");
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState([]);

  const [dateFilter, setDateFilter] = React.useState<any>({
    start_date: "",
    end_date: "",
  });

  const [pendingData, setPendingData] = React.useState([]);
  const [completedData, setCompletedData] = React.useState([]);

  const navigate = useNavigate();

  const columns: ColumnsType<DataType> = [
    {
      title: "Order Id",
      dataIndex: "order_id",
      key: "order_id",
      sorter: (a: any, b: any) => a.order_id - b.order_id,
    },
    {
      title: "Order Date",
      dataIndex: "order_date",
      key: "order_date",
      sorter: (a: any, b: any) =>
        new Date(a.order_date).getTime() - new Date(b.order_date).getTime(),
    },
    {
      title: "Name",
      dataIndex: "user_name",
      key: "name",
      render: (text) => <a className="capitalize">{text}</a>,
    },
    {
      title: "Items",
      dataIndex: "pre_order_list",
      key: "address",
      render: (text, record: any) => {
        return Object.keys(record.pre_order_list)?.map((item: any) => {
          return (
            <p className="whitespace-nowrap">
              {record.pre_order_list[item].product_name} x{" "}
              <span className="text-red-500">
                {record.pre_order_list[item].qty}
              </span>
            </p>
          );
        });
      },
    },
    {
      title: "Total price",
      key: "total_price",
      sorter: (a: any, b: any) => a.total_price - b.total_price,
      render: (_, record: any) => (
        <Space size="middle">
          <p>${record.total_price?.toFixed(2)}</p>
        </Space>
      ),
    },
    {
      title: "Discount",
      key: "discount",
      render: (_, record: any) => (
        <Space size="middle">
          <p>${record.discount}</p>
        </Space>
      ),
    },
    {
      title: "Status",
      key: "order_status",
      dataIndex: "tags",
      render: (_, record: any) => (
        <>
          <Select
            value={record?.order_status}
            style={{ width: 160 }}
            className={
              "border-[1px] border-gray.500 rounded-md text-[#fff] " +
              (record?.order_status === "In Progress"
                ? "bg-green-500"
                : record?.order_status === "Preparing food"
                ? "bg-yellow-400"
                : record?.order_status === "Order Ready"
                ? "bg-orange-500"
                : record?.order_status === "Order Closed"
                ? "bg-gray-500"
                : "")
            }
            onChange={(value) => {
              changeOrderStatus(record?.order_id, value);
            }}
            disabled={record?.order_status === "Order Closed"}
            options={[
              { value: "In Progress", label: "In Progress" },
              { value: "Preparing food", label: "Preparing food" },
              { value: "Order Ready", label: "Order Ready" },
              { value: "Order Closed", label: "Order Closed" },
            ]}
          />
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record: any) => (
        <Space size="middle">
          <a
            className="underline"
            onClick={() => {
              printInvoice(record.order_id);
            }}
          >
            Payslip
          </a>
        </Space>
      ),
    },
  ];

  const printInvoice = async (id: any) => {
    try {
      setLoading(true);
      const res = await CommonApi.postPayslip({ order_id: id });

      if (res) {
        var binaryData = [];
        binaryData.push(res);
        const url: any = window.URL.createObjectURL(
          new Blob(binaryData, { type: "application/pdf" })
        );
        window.open(url);
      }
    } catch (err: any) {
      const errMsg = err.response.data.message || err.message;
      toast.error(errMsg);
    } finally {
      setLoading(false);
    }
  };

  const getOrders = async () => {
    try {
      setLoading(true);
      const res = await CommonApi.getAdminOrders();

      if (res.success) {
        console.log("order data", res.data);
        const arr: any = [];
        Object.keys(res.data?.values)?.map((key) => {
          arr.push(res.data?.values[key]);
        });
        setData(arr);
        setPendingData(
          arr.filter((item: any) => item.order_status !== "Order Closed")
        );
        setCompletedData(
          arr.filter((item: any) => item.order_status === "Order Closed")
        );
      }
    } catch (err: any) {
      const errMsg = err.response.data.message || err.message;
      toast.error(errMsg);
    } finally {
      setLoading(false);
    }
  };

  const changeOrderStatus = async (id: any, status: any) => {
    try {
      setLoading(true);
      const res = await CommonApi.postAdminOrder({
        order_id: id,
        order_status: status,
      });

      if (res.success) {
        toast.success("Order status updated successfully");
        getOrders();
      }
    } catch (err: any) {
      const errMsg = err.response.data.message || err.message;
      toast.error(errMsg);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setInterval(async () => {
      getOrders();
    }, 60000);
  }, []);

  const logout = async () => {
    try {
      const res = await CommonApi.postLogout();

      if (res.success) {
        navigate("/");
      }
    } catch (err: any) {
      const errMsg = err.response.data.message || err.message;
      toast.error(errMsg);
    }
  };

  useEffect(() => {
    if (dateFilter?.start_date && dateFilter?.end_date) {
      console.log("date filter", dateFilter);
      const filteredData = data.filter((item: any) => {
        const date = new Date(item?.order_date);
        const startDate = new Date(dateFilter?.start_date);
        const endDate = new Date(dateFilter?.end_date);
        return date >= startDate && date <= endDate;
      });
      // setPendingData(
      //   filteredData.filter((item: any) => item.order_status !== "Order Closed")
      // );
      setCompletedData(
        filteredData.filter((item: any) => item.order_status === "Order Closed")
      );
    } else {
      setPendingData(
        data.filter((item: any) => item.order_status !== "Order Closed")
      );
      setCompletedData(
        data.filter((item: any) => item.order_status === "Order Closed")
      );
    }
  }, [dateFilter?.start_date]);

  return (
    <div>
      {loading && <Loader />}
      <div className="bg-[var(--secondary)] border-b-[1px]  shadow-lg flex border-bottom-[1px]">
        <div className="container flex mx-auto items-center justify-between py-[15px]">
          <div className="flex">
            <img src={logo} alt="" className="w-[120px] cursor-pointer" />
          </div>
          <button
            className="bg-[var(--primary)] text-white w-[fit-content]  font-semibold rounded-[4px] px-[15px]  py-[8px] mt-[20px]"
            onClick={() => {
              logout();
            }}
          >
            logout
          </button>
        </div>
      </div>

      <div className="container py-[50px] min-h-[90vh] mx-auto">
        <div className="flex gap-[10px]">
          {orderState.map((state, index) => (
            <button
              onClick={() => setShow(state)}
              className={
                (state == show
                  ? "bg-[var(--primary)] text-[#fff]"
                  : "text-[var(--primary)] ") +
                " capitalize border-[1px] border-[var(--primary)]  rounded-[8px] px-[20px] py-[5px]"
              }
            >
              {state}
            </button>
          ))}
        </div>

        <button
          onClick={() => {
            getOrders();
          }}
          className="bg-[#000] text-[#fff] py-[5px] px-[10px] rounded-[8px] mt-[20px]"
        >
          Refresh Orders
          <HiRefresh className="inline-block ml-[5px]" />
        </button>
        {/* Table Section */}
        {show == "completed" && (
          <div className="mt-[30px]">
            <RangePicker
              value={
                dateFilter?.start_date && dateFilter?.end_date
                  ? [
                      dayjs(dateFilter?.start_date, "YYYY/MM/DD"),
                      dayjs(dateFilter?.end_date, "YYYY/MM/DD"),
                    ]
                  : null
              }
              onChange={(value: any) => {
                value
                  ? setDateFilter({
                      start_date: value[0],
                      end_date: value[1],
                    })
                  : setDateFilter({
                      start_date: "",
                      end_date: "",
                    });
              }}
            />

            <div className="flex w-[100%] justify-start gap-[30px] mt-[30px]">
              <div className="h-[200px]  rounded-[8px] w-[200px] bg-gray-400 flex flex-col items-center justify-center">
                <h2 className="text-xl text-white">Total Orders</h2>
                <p className="text-2xl mt-[10px] text-gray-100">
                  {completedData?.length}
                </p>
              </div>

              <div className="h-[200px] rounded-[8px] w-[200px] bg-cyan-500 flex flex-col items-center justify-center">
                <h2 className="text-xl text-white">Total Revenue</h2>
                <p className="text-2xl mt-[10px] text-gray-100">
                  $
                  {completedData
                    ?.reduce(function (sum: any, tax: any) {
                      return sum + tax?.total_price;
                    }, 0)
                    ?.toFixed(2)}
                </p>
              </div>
            </div>
          </div>
        )}
        {show == "new" && (
          <div className="mt-[30px]">
            <div className="flex  w-[100%] justify-start gap-[30px] mt-[30px]">
              <div className="h-[200px]  rounded-[8px] w-[200px] bg-gray-400 flex flex-col items-center justify-center">
                <h2 className="text-xl text-white">Total Orders</h2>
                <p className="text-2xl mt-[10px] text-gray-100">
                  {pendingData?.length}
                </p>
              </div>

              <div className="h-[200px] rounded-[8px] w-[200px] bg-cyan-500 flex flex-col items-center justify-center">
                <h2 className="text-xl text-white">Total Revenue</h2>
                <p className="text-2xl mt-[10px] text-gray-100">
                  $
                  {pendingData
                    ?.reduce(function (sum: any, tax: any) {
                      return sum + tax?.total_price;
                    }, 0)
                    ?.toFixed(2)}
                </p>
              </div>
            </div>
          </div>
        )}
        {data && (
          <div className="max-w-[100%] overflow-scroll">
            <Table
              className="mt-[50px]"
              columns={columns}
              dataSource={show == "new" ? pendingData : completedData}
              pagination={false}
            />
          </div>
        )}
      </div>

      <Footer />
    </div>
  );
};

export default Order;
