import { api } from "./config/axiosConfig";
import { defineCancelApiObject } from "./config/axiosUtils";


export const CommonApi = {
    getCategoryList: async (query, cancel = false) => {
        const response = await api.request({
            url: "curryapp/category_list",
            method: "GET",
            headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
            params: query,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
        });

        return response.data;
    },

    getProductData: async (query, cancel = false) => {
        const response = await api.request({
            url: "curryapp/menu",
            method: "GET",
            headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
            params: query,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
        });

        return response.data;
    },

    postCheckout: async (data, cancel = false) => {
        const response = await api.request({
            url: "curryapp/checkout",
            method: "POST",
            headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
            data: data,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
        });

        return response.data;
    },
    getAdminOrders: async (query, cancel = false) => {
        const response = await api.request({
            url: "curryapp/order",
            method: "GET",
            headers: { 'Authorization': `Bearer ${sessionStorage.getItem('token')}` },
            params: query,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
        });

        return response.data;
    },

    postAdminOrder: async (data, cancel = false) => {
        const response = await api.request({
            url: "curryapp/order_status",
            method: "POST",
            headers: { 'Authorization': `Bearer ${sessionStorage.getItem('token')}` },
            data: data,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
        });

        return response.data;
    },

    postLogin: async (data, cancel = false) => {
        const response = await api.request({
            url: "curryapp/login",
            method: "POST",
            data: data,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
        });

        return response.data;
    },
    postLogout: async (cancel = false) => {
        const response = await api.request({
            url: "curryapp/logout",
            method: "GET",
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
        });

        return response.data;
    },

    postPayslip: async (data, cancel = false) => {
        const response = await api.request({
            url: "curryapp/pdf_payslep",
            method: "POST",
            headers: { 'Authorization': `Bearer ${sessionStorage.getItem('token')}` },
            data: data,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
        });

        return response.data;
    },

    postGetInTouch: async (data, cancel = false) => {
        const response = await api.request({
            url: "curryapp/get_in_touch",
            method: "POST",
            data: data,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
        });
        return response.data;
    }

}

const cancelApiObject = defineCancelApiObject(CommonApi);
