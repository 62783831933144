export const calculateTotalSum = (cartData: any[]) => {
  return cartData.reduce((acc: any, item: any) => {
    return acc +( Number(item.product_price) * Number(item.quantity));
  }, 0);
};
export const calculateTotalDiscount = (cartData: any[]) => {
  return cartData.reduce((acc: any, item: any) => {
    return acc +(Number(item?.quantity))*Number(item?.discount);
  }, 0);
};
export const TotalTaxAmount = (cartData: any[]) => {
  return cartData.reduce((acc: any, item: any) => {
    return acc +( (Number(item.product_price) * Number(item.quantity))-( Number(item.discount)*Number(item.quantity)));
  }, 0);
};

