import React, { useEffect } from "react";
import Input from "../../components/Input/Input";
import { AdminAPI } from "../../apis/adminAPI.js";
import { toast, ToastContainer } from "react-toastify";
import Loader from "../../components/Loader";
import { CommonApi } from "../../apis/commonApi";
import { useNavigate } from "react-router-dom";
import { ORDERS } from "../../navigation/Constant";

import logo from "../../assets/images/logo.png";

const Login = () => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const navigate = useNavigate();

  const buttonRef = React.useRef<any>(null);

  const handleLogin = async () => {
    console.log("abc", email, password);
    if (!email || !password) {
      return toast.error("Please fill all the details", {
        position: "top-right",
        autoClose: 5000,
        closeOnClick: true,
      });
    }

    try {
      setLoading(true);
      const data = await CommonApi.postLogin({ username: email, password });

      if (data.success) {
        sessionStorage.setItem("token", data.data.token.access);
        navigate(ORDERS);
      }
    } catch (error: any) {
      console.log("here");
      const errMsg =
        error?.response?.data?.message ||
        error?.message ||
        "something went wrong";
      console.log(errMsg);
      return toast.error(errMsg, {
        position: "top-right",
        autoClose: 5000,
        closeOnClick: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    if (name === "email") {
      setEmail(value);
    } else if (name === "password") {
      setPassword(value);
    }
  };

  useEffect(() => {
    const listener = (event: any) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        if(buttonRef){
          buttonRef.current.click();
        }
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  return (
    <div className="min-h-[100vh] flex items-center">
      {loading && <Loader />}

      <div className="flex flex-col gap-[10px] card md:w-[50%] w-[90%] shadow-[0px_0px_8px_rgba(0,0,0,0.1)] bg-[#3c3c3c]/[0.1] py-[100px] px-[20px] rounded-[8px]  mx-auto">
        <img
          onClick={() => navigate("/")}
          src={logo}
          className="h-[40px] cursor-pointer object-contain mb-[30px]"
        />

        <h1 className="text-center text-2xl font-semibold">Login</h1>
        <div className="flex flex-col gap-[10px] md:w-[50%] w-[90%] mx-auto">
          <Input
            label="Username"
            name="email"
            type="email"
            placeholder="Enter your username"
            handleChange={handleChange}
            value={email}
          />
          <Input
            label="Password"
            name="password"
            type="password"
            placeholder="Enter your password"
            handleChange={handleChange}
            value={password}
          />
        </div>
        <button
          ref={buttonRef}
          className="bg-[var(--primary)] text-white w-[50%] mx-auto font-semibold rounded-[4px]  py-[8px] mt-[20px]"
          onClick={() => {
            handleLogin();
          }}
        >
          Proceed
        </button>
      </div>
    </div>
  );
};

export default Login;
