import React, { useEffect } from "react";
import DefaultLayout from "./DefaultLayout";
import { CommonApi } from "../apis/commonApi";
import { useDispatch } from "react-redux";
import {
  setBuildCurry,
  setCategoryData,
  setProductData,
} from "../redux/productCart/productCartSlice";
import Loader from "../components/Loader";
import { useSearchParams } from "react-router-dom";

interface Props {
  children: React.ReactNode;
}

const ProductLayout = ({ children }: Props) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = React.useState<boolean>(false);

  const getData = async () => {
    try {
      setLoading(true);
      const [response, response2] = await Promise.all([
        CommonApi.getCategoryList(),
        CommonApi.getProductData(),
      ]);

      if (response.success) {
        const arr = response.data.values.sort(function (a: any, b: any) {
          return a.sequence - b.sequence;
        });
        dispatch(setCategoryData(arr));
      }

      if (response2.success) {
        dispatch(setProductData(response2.data.values));
      }
    } catch (err: any) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (searchParams.get("buildCurry") === "true") {
      dispatch(setBuildCurry(true));
    } else {
      dispatch(setBuildCurry(false));
    }
  }, [searchParams]);

  return (
    <DefaultLayout>
      {loading ? (
        <div className="h-[600px]">
          <Loader />
        </div>
      ) : (
        children
      )}
    </DefaultLayout>
  );
};

export default ProductLayout;
