import React, { useEffect, useState, useRef } from "react";

import { useDispatch, useSelector } from "react-redux";
import ProductLayout from "../../layouts/ProductLayout";
import { RootState } from "../../redux/store";
import { toast } from "react-toastify";
import {
  setCartData,
  setSelectedCategory,
} from "../../redux/productCart/productCartSlice";
import { useSearchParams } from "react-router-dom";
import ChooseCategoryModal from "./ChooseCategoryModal";
import { ProductCard } from "./ProductCard";
import Scroll from "../../components/Scroll/Scroll";

const ProductV2 = () => {
  const {
    productData,
    categoryData,
    curryData,
    buildCurry,
    selectedCategory,
  } = useSelector((state: RootState) => state.cart);

  const dispatch = useDispatch();

  const ref = React.useRef<any>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const [categoryShow, setCategoryShow] = useState<any>([]);


  // Filter category to show
  useEffect(() => {
    if (categoryData?.length > 0) {
      if (buildCurry) {
        const showCategoryData = categoryData?.filter(
          (item: any) => item?.category_id < 5
        );
        setCategoryShow(showCategoryData);
        dispatch(setSelectedCategory(showCategoryData[0]));
      } else {
        const showCategoryData = categoryData?.filter(
          (item: any) => item?.category_id > 4
        );

        setCategoryShow(showCategoryData);
        dispatch(setSelectedCategory(showCategoryData[0]));
      }
    }
  }, [buildCurry, categoryData]);

  //   Handle step change

  const [searchParams, setSearchParams] = useSearchParams();

  const handleStepChange = () => {
    if (selectedCategory?.category_id < 9) {
      if (selectedCategory?.category_id === 4) {
        setIsCategoryShowModalOpen(true);
        searchParams.set("buildCurry", "false");
        setSearchParams(searchParams);
      }
      dispatch(
        setSelectedCategory(
          categoryData?.find(
            (item: any) =>
              item.category_id === Number(selectedCategory?.category_id) + 1
          )
        )
      );
      scrollToCategory(categoryData?.find(
        (item: any) =>
          item.category_id === Number(selectedCategory?.category_id) + 1
      )?.category_id)
    }
    window.scrollTo(0, 0);
  };

  // Change flow to product flow

  const [isCategoryShowModalOpen, setIsCategoryShowModalOpen] =
    useState<boolean>(false);

  const pickAnotherCurryPlate = () => {
    searchParams.set("buildCurry", "true");
    setSearchParams(searchParams);
    setSelectedCategory(
      categoryData?.find((item: any) => item.category_id === 1)
    );
  };

  // Add type annotation to useRef

  const scrollToCategory = (categoryId: any) => {
    const container = containerRef.current;
    const categoryElement = document.getElementById(`category_${categoryId}`);
    if (container && categoryElement && selectedCategory) {
      const containerRect = container.getBoundingClientRect();
      const categoryRect = categoryElement.getBoundingClientRect();
      const scrollAmount = categoryRect.left - containerRect.left;

      container.scrollLeft += scrollAmount;
    }
  };

  return (
    <ProductLayout>
      <Scroll />
      <>
        <ChooseCategoryModal
          isOpen={isCategoryShowModalOpen}
          onClose={() => setIsCategoryShowModalOpen(false)}
        />
        <section
          className="bg-[#fff] sm:px-[120px] px-[14px] sm:py-[52px] py-[32px] bg-cover object-contain"
          style={{ position: "relative", zIndex: 0 }}
        >
          <div className="shadow bg-[#ffff] rounded-[10px] border">
            <div
              ref={ref}
              className="container mx-auto flex flex-col items-center justify-center"
            >
              <div className="sm:mt-[60px] mt-[34px] text-center justify-center items-center">
                <div className="capitalize">
                  <h2 className="md:text-[40px] text-[24px] font-[600]">
                    {selectedCategory?.category_title}
                  </h2>
                  <p className="font-semibold sm:text-xl text-[12px] text-center text-[#636363] h-[10px] mt-[10px]">
                    {selectedCategory?.category_desc}
                  </p>
                </div>
              </div>
            </div>

            <div className="col-span-3 w-[100%] flex items-center justify-center ">
              {curryData?.length > 0 && selectedCategory?.category_id > 4 && (
                <p
                  className="text-[black] font-semibold border-[2px] border-[var(--primary)] px-[10px] rounded-[10px] cursor-pointer mt-[30px]"
                  onClick={() => {
                    pickAnotherCurryPlate();
                  }}
                >
                  Pick Another Curry Plate
                </p>
              )}
            </div>

            <div className="category flex sm:justify-center sm:gap-[24px] gap-[12px] justify-start overflow-x-auto mx-4 sm:mx-0 sm:mt-8 mt-12" ref={containerRef}>
              {categoryShow?.map((item: any, index: number) => {
                return (
                  <div
                    id={`category_${item.category_id}`}
                    key={item.category_id}
                    className={`flex items-center justify-center cursor-pointer p-2 whitespace-nowrap ${selectedCategory?.category_id === item.category_id
                      ? "border-b-[3px] border-[var(--primary)] text-[var(--primary)]"
                      : ""
                      }`}
                    onClick={() => {
                      if (item?.category_id > 4) {
                        dispatch(setSelectedCategory(item));
                        scrollToCategory(item.category_id);
                      }
                    }}
                    style={{ minWidth: "126px", maxWidth: "200px" }}
                  >
                    <p className="md:text-[20px] text-base">
                      {item?.category_name}
                    </p>
                  </div>
                );
              })}
            </div>

            <div
              className={`pb-[36px] scroll-smooth mt-[20px] ${productData.length > 6 ? " overflow-y-auto no-scrollbar" : ""
                }`}
            >
              <div className="mt-[20px] sm:w-[70%] mx-[8px] sm:mx-auto grid md:grid-cols-3 grid-cols-2 gap-[10px] md:gap-[28px]">
                {[
                  ...productData?.filter(
                    (item: any) =>
                      item.category_id === selectedCategory?.category_id
                  ),
                ].map((item, i) => {
                  return (
                    <ProductCard
                      key={i}
                      item={item}
                      stepChange={handleStepChange}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </section>
      </>
    </ProductLayout>
  );
};

export default ProductV2;
