import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { toast } from "react-toastify";
import { setCartData } from "../../redux/productCart/productCartSlice";

interface ProductCardInterface {
  item: any;
  stepChange: any;
}

export const ProductCard = ({ item, stepChange }: ProductCardInterface) => {
  const { cartData, selectedCategory } = useSelector(
    (state: RootState) => state.cart
  );

  const dispatch = useDispatch();

  return (
    <div
      key={item.product_id}
      className="cursor-pointer mx-auto w-[100%] col-span-1 flex flex-col items-center justify-center rounded-[20px] md:p-[20px] p-[10px] max-w-[280px]"
      onClick={async () => {
        const cartItem = cartData.find(
          (cartItem: any) => cartItem.product_id === item.product_id
        );

        if (
          selectedCategory?.category_id < 5 &&
          cartData.find(
            (cartItem: any) => item.category_id === cartItem.category_id
          )
        ) {
          toast.error("You can only add one item from each category");
          return;
        }

        if (cartItem) {
          let newCartData = cartData.map((cartItem: any) => {
            if (cartItem.product_id === item.product_id) {
              return {
                ...cartItem,
                quantity: cartItem.quantity + 1,
              };
            }
            return cartItem;
          });

          dispatch(setCartData(newCartData));

          return;
        }
        const newCartData = [...cartData, { ...item, quantity: 1 }];
        await dispatch(setCartData(newCartData));
        stepChange();
      }}
    >
      <div className="shadow border-[var(--secondary)] border-4 rounded-[50%] w-[50%] md:w-[100%] md:max-w-[140px] max-w-[78px] h-[78px] overflow-hidden flex md:h-[140px]  mb-[-40px] z-[200] bg-[#fff]">
        <img
          src={item?.product_photo}
          alt=""
          className="object-cover w-[100%]"
        />
      </div>
      <div className="hover:bg-[var(--primary)] group transition-all hover:text-white shadow pb-[10px] md:w-[245px] w-[160px] md:h-[240px] h-[200px] px-[26px] rounded-[35px] pt-[60px] bg-[var(--secondary)] flex flex-col text-center items-center justify-center">
        <h3 className="md:text-lg text-[13px] font-semibold group-hover:text-white">
          {item?.product_name}
        </h3>
        <div className="border-y-[1px] border-[#D3D3D3] my-2">
          <p className="mt-[5px] text-[#636363] md:text-[14px] text-[10px] mb-[10px] group-hover:text-white">
            {item?.product_desc}
          </p>
        </div>
        <div className="mt-[auto] flex items-center gap-[124px] mb-[12px]">
          <div className="flex md:gap-[124px] gap-[28px] ">
            <p className="md:text-[20px] text-[16px] text-[var(--primary)] group-hover:text-white">
              {Number(item?.product_price - item?.discount) > 0 && (
                <>${Number(item?.product_price - item?.discount)?.toFixed(2)}</>
              )}
            </p>
            <div className="group transition-all hover:text-white"></div>
          </div>
        </div>
      </div>
    </div>
  );
};
