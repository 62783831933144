import React, { useEffect } from "react";
import ModalContainer from "../../components/ModalContainer/ModalContainer";
import AnotherCurryPlate from "../../assets/images/itemCurry.png";
import Appetizer from "../../assets/images/itemAppetizer.png";
import Tandoori from "../../assets/images/itemTandoori.png";
import ExtrasNSides from "../../assets/images/item1.png";
import Desserts from "../../assets/images/itemDessert.png";
import DrinksNShakes from "../../assets/images/itemDrink.png";
import { RootState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedCategory } from "../../redux/productCart/productCartSlice";
import { useSearchParams } from "react-router-dom";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const ChooseCategoryModal = ({ isOpen, onClose }: Props) => {
  const categoryData = useSelector(
    (state: RootState) => state.cart.categoryData
  );

  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  const handleCategoryClick = (category: number) => {
    const selectedCategory = categoryData?.find(
      (item: any) => item.category_id === category
    );

    if (category === 1) {
      searchParams.set("buildCurry", "true");
      setSearchParams(searchParams);
    }

    dispatch(setSelectedCategory(selectedCategory));
    onClose();
  };

  useEffect(() => {
    // Scroll to top of modal when it opens
    if (isOpen) {
      window.scrollTo(0, 0);
    }
  }, [isOpen]);

  return (
    <ModalContainer
      title="Order"
      modalOpen={isOpen}
      closeModal={() => onClose()}
      width={40}
    >
      <div className="flex flex-col sm:px-[30px] px-[10px] p-2  max-h-[520px] overflow-hidden no-scrollbar overflow-y-scroll">
        <div className="grid md:grid-cols-6 grid-cols-2 sm:gap-[20px] gap-[10px] justify-between">
          <div
            className="flex items-center m-auto justify-center flex-col cursor-pointer"
            onClick={() => {
              handleCategoryClick(1);
            }}
          >
            <div className="rounded-[50%] mb-[-40px] z-[200] p-[10px] bg-[#fff]">
              <img src={AnotherCurryPlate} alt="" className="w-[120px]" />
            </div>
            <div className="pb-[20px] md:w-[200px] w-[160px] h-[120px] px-[30px] rounded-[20px] pt-[60px] bg-[var(--secondary)] hover:text-[#ffff] flex items-center justify-center hover:bg-[var(--primary)]">
              <p className="text-center">Another Curry Plate</p>
            </div>
          </div>
          <div
            className="flex items-center m-auto justify-center flex-col cursor-pointer"
            onClick={() => {
              handleCategoryClick(5);
            }}
          >
            <div className="rounded-[50%] mb-[-40px] z-[200] p-[10px] bg-[#fff]">
              <img src={Appetizer} alt="" className="w-[120px]" />
            </div>
            <div className="pb-[20px] md:w-[200px] w-[160px] h-[120px] px-[30px] rounded-[20px] pt-[60px] bg-[var(--secondary)] hover:text-[#ffff] flex items-center justify-center hover:bg-[var(--primary)]">
              <p className="text-center">Appetizer</p>
            </div>
          </div>
          <div
            className="flex items-center m-auto justify-center flex-col cursor-pointer"
            onClick={() => {
              handleCategoryClick(6);
            }}
          >
            <div className="rounded-[50%] mb-[-40px] z-[200] p-[10px] bg-[#fff]">
              <img src={Tandoori} alt="" className="w-[120px]" />
            </div>
            <div className="pb-[20px] md:w-[200px] w-[160px] h-[120px] px-[30px] rounded-[20px] pt-[60px] bg-[var(--secondary)] hover:text-[#ffff] flex items-center justify-center hover:bg-[var(--primary)]">
              <p className="text-center">Tandoori</p>
            </div>
          </div>
          <div
            className="flex items-center m-auto justify-center flex-col cursor-pointer"
            onClick={() => {
              handleCategoryClick(7);
            }}
          >
            <div className="rounded-[50%] mb-[-40px] z-[200] p-[10px] bg-[#fff]">
              <img src={ExtrasNSides} alt="" className="w-[120px]" />
            </div>
            <div className="pb-[20px] md:w-[200px] w-[160px] h-[120px] px-[30px] rounded-[20px] pt-[60px] bg-[var(--secondary)] hover:text-[#ffff] flex items-center justify-center hover:bg-[var(--primary)]">
              <p className="text-center">Extra & Sides</p>
            </div>
          </div>
          <div
            className="flex items-center m-auto justify-center flex-col cursor-pointer"
            onClick={() => {
              handleCategoryClick(8);
            }}
          >
            <div className="rounded-[50%] mb-[-40px] z-[200] p-[10px] bg-[#fff]">
              <img src={Desserts} alt="" className="w-[120px]" />
            </div>
            <div className="pb-[20px] md:w-[200px] w-[160px] h-[120px] px-[30px] rounded-[20px] pt-[60px] bg-[var(--secondary)] hover:text-[#ffff] flex items-center justify-center hover:bg-[var(--primary)]">
              <p className="text-center">Dessert</p>
            </div>
          </div>
          <div
            className="flex items-center m-auto justify-center flex-col cursor-pointer"
            onClick={() => {
              handleCategoryClick(9);
            }}
          >
            <div className="rounded-[50%] mb-[-40px] z-[200] p-[10px] bg-[#fff]">
              <img src={DrinksNShakes} alt="" className="w-[120px]" />
            </div>
            <div className="pb-[20px] md:w-[200px] w-[160px] h-[120px] px-[30px] rounded-[20px] pt-[60px] bg-[var(--secondary)] hover:text-[#ffff] flex items-center justify-center hover:bg-[var(--primary)]">
              <p className="text-center">Drink & Shake</p>
            </div>
          </div>
        </div>
        <div className="flex gap-[20px] mx-auto sm:mt-[30px] mt-[20px]">
          <button
            className="border-0 outline-0 b rounded-[50px] bg-[var(--primary)] py-[6px] px-[28px] text-white font-600 w-[100%] mt-[10px] flex items-center gap-[10px] justify-center"
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </ModalContainer>
  );
};

export default ChooseCategoryModal;
