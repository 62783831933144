import React from "react";
import ModalContainer from "../ModalContainer/ModalContainer";
import { Button } from "antd";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

const IncompleteCurryModal = ({ isOpen, onClose, onSubmit }: Props) => {
  return (
    <ModalContainer
      modalOpen={isOpen}
      title="Build Curry"
      width={30}
      closeModal={() => onClose()}
    >
      <>
        <p className="text-sm text-gray-500">
          There is an incomplete order in your cart. Are you sure you want to leave?
        </p>
        <div className="flex justify-end gap-[10px] mt-[20px]">
          <Button
            type="link"
            onClick={() => onClose()}
            className="border-[#ffc215] text-[#ffc215] hover:bg-[#ffc215] hover:text-white"
          >
            Cancel
          </Button>
          <Button
            type="link"
            onClick={() => {
              onSubmit();
            }}
            className="bg-[#ffc215] text-white hover:bg-[#ffc215] hover:text-white"
          >
            Proceed
          </Button>
        </div>
      </>
    </ModalContainer>
  );
};

export default IncompleteCurryModal;
