import deleteicon from "../../assets/icons/deleteIcon.svg";
import { AiOutlineShrink, AiOutlineExpandAlt } from "react-icons/ai";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import {
  setCartData,
  setCurryData,
  setIsCartOpen,
  setSelectedCategory,
} from "../../redux/productCart/productCartSlice";
import { useEffect, useState } from "react";

import IncompleteCurryModal from "../Modal/IncompleteCurryModal";
import { calculateTotalSum } from "../../utils";
import CheckoutModal from "./CheckoutModal";
import { Console, log } from "console";

const Cart = () => {
  const dispatch = useDispatch();
  const isCartOpen = useSelector((state: RootState) => state.cart.isCartOpen);
  const curryData = useSelector((state: RootState) => state.cart.curryData);
  const cartData = useSelector((state: RootState) => state.cart.cartData);

  const categoryData = useSelector(
    (state: RootState) => state.cart.categoryData
  );

  const [checkoutModalOpen, setCheckoutModalOpen] = useState<boolean>(false);

  const handleCartOpen = () => {
    dispatch(setIsCartOpen(!isCartOpen));
    console.log(isCartOpen)
  };

  const [isIncompleteCurryModalOpen, setIsIncompleteCurryModalOpen] = useState(false);

  const isPhoneView = window.innerWidth <= 600;

  useEffect(() => {
    console.log(isPhoneView)
    if (!isPhoneView &&(cartData.length > 0 && !isCartOpen)) {
      dispatch(setIsCartOpen(true));
    }
  }, [cartData, isPhoneView]);


  useEffect(() => {
    if (cartData?.filter((item: any) => item?.category_id < 5)?.length === 4) {
      const newCartData = cartData.filter((item: any) => item.category_id > 4);
      const newCurryData = cartData.filter((item: any) => item.category_id < 5);

      dispatch(setCurryData([...curryData, newCurryData]));
      dispatch(setCartData(newCartData));
    }
  }, [cartData]);

  return (
    <>
      <div className="product-cart z-[1000]">
        <div className="flex flex-col sm:max-h-[500px] max-h-[300px] overflow-hidden no-scrollbar">
          <div className="product-cart__header">
            <h3 className="text-[22px] font-semibold">My Order</h3>
            <span
              onClick={handleCartOpen}
              onKeyDown={handleCartOpen}
              className="product-cart__header--close"
            >
              {isCartOpen ? <AiOutlineShrink /> : <AiOutlineExpandAlt />}
            </span>
          </div>
          {isCartOpen && (
            <div className="product-cart__body">
              {curryData.map((item1: any, i: any) => (
                <>
                  <div className="flex justify-between items-center">
                    <p className="my-[10px] text-[var(--primary)] flex justify-between items-center">
                      Curry Plate {Number(i) + 1}
                    </p>
                    <div
                      className="w-[30px] h-[30px] rounded-[50%] flex items-center justify-center bg-[#fff] ml-auto"
                      onClick={() => {
                        const newCartData = [...curryData];

                        newCartData.splice(i, 1);

                        dispatch(setCurryData(newCartData));
                      }}
                    >
                      <img
                        src={deleteicon}
                        className="w-[18px] cursor-pointer"
                        alt=""
                      />
                    </div>
                  </div>
                  {item1?.map((item: any, i2: any) => (
                    <div
                      key={i + item?.product_name}
                    >
                      <div className="ml-[10px] relative flex items-center justify-between">
                        <h2 className="font-[500] flex gap-[6px] items-center">
                          {item?.product_name}
                          <p className="text-[var(--primary)] text-[12px] whitespace-nowrap">
                            x {item?.quantity}
                          </p>
                        </h2>
                        <div className="flex flex-row flex-nowrap items-center gap-1">
                          <p className="text-[14px] font-[500] text-[var(--primary)]">
                            $
                            {(
                              Number(item?.product_price) * item?.quantity
                            )?.toFixed(2)}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              ))}

              {categoryData?.map((item: any, i: any) => (
                <>
                  {cartData?.filter(
                    (item2: any) => item2?.category_id == item?.category_id
                  ).length > 0 && (
                    <div className="flex flex-col">
                      <p className="text-[var(--primary)] flex justify-between items-center">
                        {item?.category_name}
                      </p>

                      <div>
                        {cartData
                          ?.filter(
                            (item2: any) =>
                              item2?.category_id === item?.category_id
                          )
                          ?.map((item3: any) => {
                            return (
                              <div className="ml-[10px] relative flex items-center justify-between">
                                <h2 className="font-[500] flex gap-[6px] items-center">
                                  {item3?.product_name}
                                  <p className="text-[var(--primary)] text-[12px] whitespace-nowrap">
                                    x {item3?.quantity}
                                  </p>
                                </h2>
                                <div className="flex flex-row flex-nowrap items-center gap-1">
                                  <p className="text-[14px] font-[500] text-[var(--primary)]">
                                    $
                                    {(
                                      Number(item3?.product_price) *
                                      item3?.quantity
                                    )?.toFixed(2)}
                                  </p>
                                  <div className="w-[30px] h-[30px] rounded-[50%] flex items-center justify-center bg-[#fff] ml-auto">
                                    <img
                                      src={deleteicon}
                                      className="w-[18px] cursor-pointer"
                                      alt=""
                                      onClick={() => {
                                        let newCartData = [...cartData];

                                        const productIndex =
                                          newCartData.findIndex(
                                            (item4: any) =>
                                              item4.product_id ===
                                              item3.product_id
                                          );
                                        if (
                                          newCartData[productIndex].quantity > 1
                                        ) {
                                          newCartData = newCartData?.map(
                                            (item5: any, index: number) => {
                                              if (index === productIndex) {
                                                return {
                                                  ...item5,
                                                  quantity: item5.quantity - 1,
                                                };
                                              }
                                              return item5;
                                            }
                                          );
                                        } else {
                                          const deletedItem =
                                            newCartData[productIndex];

                                          if (
                                            newCartData[productIndex]
                                              .category_id < 5
                                          ) {
                                            const itemProd =
                                              newCartData[productIndex];

                                            newCartData = newCartData?.filter(
                                              (item5: any, index: number) => {
                                                if (
                                                  item5.category_id <
                                                    itemProd.category_id ||
                                                  item5.category_id > 5
                                                ) {
                                                  return item5;
                                                }
                                              }
                                            );
                                          } else {
                                            newCartData.splice(productIndex, 1);
                                          }

                                          dispatch(
                                            setSelectedCategory(
                                              categoryData?.find(
                                                (item: any) =>
                                                  item.category_id ===
                                                  deletedItem.category_id
                                              )
                                            )
                                          );
                                        }

                                        dispatch(setCartData(newCartData));
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  )}
                </>
              ))}

              <div className="flex flex-col gap-[10px] items-center justify-center mt-auto">
                <div className="flex items-center justify-center sm:gap-[192px] gap-[230px] px-[24px] mt-[8px]">
                  <h3 className="text-[22px] font-semibold">Total</h3>

                  <p className="text-[20px] text-[var(--primary)]">
                    $
                    {calculateTotalSum([
                      ...cartData,
                      ...curryData?.flat(Infinity),
                    ])?.toFixed(2)}
                  </p>
                </div>
                {cartData?.filter((item: any) => item.category_id > 4).length >
                  0 || curryData.length > 0 ? (
                  <button
                    className="border-0 outline-0 rounded-[8px] bg-[#228b22] py-[10px] px-[30px] text-white font-600 w-[100%] sm:mt-[8px] mt-[6px] flex items-center gap-[10px] justify-center"
                    onClick={() => {
                      if (
                        cartData?.filter((item: any) => item.category_id < 5)
                          .length > 0
                      ) {
                        setIsIncompleteCurryModalOpen(true);
                        return;
                      }

                      setCheckoutModalOpen(true);
                    }}
                  >
                    Checkout
                  </button>
                ) : (
                  <button
                    className="cursor-not-allowed border-0 outline-0 rounded-[8px] bg-[#aaaaaa] py-[10px] px-[30px] text-white font-600 w-[100%] sm:mt-[10px] mt-[6px] flex items-center gap-[10px] justify-center"
                    disabled
                  >
                    Checkout
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Checkout modal */}
      <CheckoutModal
        isOpen={checkoutModalOpen}
        onClose={() => setCheckoutModalOpen(false)}
      />

      {/* Incomplete cart Modal */}

      <IncompleteCurryModal
        isOpen={isIncompleteCurryModalOpen}
        onClose={() => setIsIncompleteCurryModalOpen(false)}
        onSubmit={() => {
          dispatch(
            setCartData(cartData?.filter((item: any) => item?.category_id > 4))
          );
          setIsIncompleteCurryModalOpen(false);
          setCheckoutModalOpen(true);
        }}
      />
    </>
  );
};

export default Cart;
