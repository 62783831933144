import React, { useEffect, useState } from "react";
import Topbar from "../../components/Topbar/Topbar";
import Navbar from "../../components/Navbar/Navbar";
import Banner1 from "../../assets/images/banner1.png";
import item1 from "../../assets/images/item1.png";
import product1 from "../../assets/images/product1.png";
import ingridient from "../../assets/images/ingridient.png";
import Footer from "../../components/Footer";
import { CommonApi } from "../../apis/commonApi";
import { useNavigate } from "react-router-dom";

import itemCoriander from "../../assets/images/itemCoriander.png";
import itemGaramMasala from "../../assets/images/itemGaramMasala.png";
import itemMustard from "../../assets/images/itemMustard.png";
import itemTurmeric from "../../assets/images/itemTurmeric.png";
import itemGaramMasala2 from "../../assets/images/itemGaramMasala2.png";

import itemDesset from "../../assets/images/itemDessert.png";
import itemDrink from "../../assets/images/itemDrink.png";
import itemCurry from "../../assets/images/itemCurry.png";
import itemAppetizer from "../../assets/images/itemAppetizer.png";
import itemTandoori from "../../assets/images/itemTandoori.png";

import { HiChevronLeft, HiChevronRight } from "react-icons/hi";
import ModalContainer from "../../components/ModalContainer/ModalContainer";
import { PRODUCT, ROOT } from "../../navigation/Constant";
import DefaultLayout from "../../layouts/DefaultLayout";
import { toast } from "react-toastify";

const SideCategory = [
  {
    name: "Extra & Sides",
    image: item1,
    category_id: 7,
    featured: false,
  },
  {
    name: "Desserts",
    image: itemDesset,
    category_id: 8,
    featured: false,
  },
  {
    name: "Drinks & Shakes",
    image: itemDrink,
    category_id: 9,
    featured: false,
  },
  {
    name: "Build Curry Plate",
    image: itemCurry,
    category_id: 1,
    featured: true,
  },
  {
    name: "Appetizers",
    image: itemAppetizer,
    category_id: 5,
    featured: false,
  },
  {
    name: "Tandoori Dishes",
    image: itemTandoori,
    category_id: 6,
    featured: false,
  },
];

const EssentialSpecies = [
  {
    image: ingridient,
    title: "Cumin",
    description:
      "These things are nutty and peppery and provide a familiar warmth that underlies a LOT of Indian dishes.",
  },
  {
    image: itemMustard,
    title: "Mustard",
    description:
      "Aromatic and pungent mustard is an essential component in many different preparations.",
  },
  {
    image: itemCoriander,
    title: "Coriander",
    description:
      "Earthy and citrusy, coriander powder is another must-have to get started cooking Indian food.",
  },
  {
    image: itemTurmeric,
    title: "Turmeric",
    description:
      "The most visually recognizable Indian spice, this stuff is roaringly bright and vibrant, with a slightly bitter flavor.",
  },
  {
    image: itemGaramMasala,
    title: "Garam Masala",
    description:
      "Garam masala is a blend of aromatic spices like cinnamon, peppercorns, cardamom, cloves, bay leaves, etc. ",
  },
  {
    image: itemGaramMasala2,
    title: "Garam Masala",
    description:
      "It is a ground powder used to add heat (and color) to dishes. With varying colors, heat, and aroma, India has a trove of spices to offer.",
  },
];

const Home = () => {
  const navigate = useNavigate();

  const [categoryData, setCategoryData] = React.useState<any>([]);
  const [selectedCategory, setSelectedCategory] = React.useState<any>({});

  const [productData, setProductData] = React.useState<any>([]);

  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [message, setMessage] = useState<string>("");

  const handleSubmitGetInTouch = async (e: React.FormEvent<HTMLElement>) => {
    e.preventDefault();

    // Email validation regular expression
    const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

    switch (true) {
      case !name:
        toast.error("Please enter name");
        return;
      case !email:
        toast.error("Please enter email");
        return;
      case !emailRegex.test(email):
        toast.error("Please enter a valid email address");
        return;
      case !phone:
        toast.error("Please enter phone");
        return;
      case !message:
        toast.error("Please enter message");
        return;
      default:
        try {
          const response = await CommonApi.postGetInTouch({
            name,
            email,
            mobile_no: phone,
            messages: message,
          });

          if (response.success) {
            toast.success("Thanks for getting in touch");
          }
          // toast.error(response.message);
        } catch (error: any) {
          toast.error(error.message);
        } finally {
          setName("");
          setEmail("");
          setPhone("");
          setMessage("");
          return;
        }
    }
  };

  const getCategory = async () => {
    try {
      const response = await CommonApi.getCategoryList();

      if (response.success) {
        setCategoryData(response.data.values);
        setSelectedCategory(response.data.values[0]);
      }
    } catch (err: any) {
      console.log(err);
    }
  };

  const getProduct = async () => {
    try {
      const response = await CommonApi.getProductData();

      if (response.success) {
        setProductData(response.data.values);
      }
    } catch (err: any) {
      console.log(err);
    }
  };

  useEffect(() => {
    getCategory();
    getProduct();
  }, []);

  const [isOrderPlaced, setIsOrderPlaced] = React.useState<boolean>(false);

  const orderPlaced = () => {
    if (localStorage.getItem("timestamp")) {
      const timestamp = JSON.parse(localStorage.getItem("timestamp") || "");

      if (timestamp.timestamp && timestamp.orderId) {
        const currentTime = new Date().getTime();

        if (currentTime - timestamp.timestamp < 5 * 60 * 1000) {
          setIsOrderPlaced(true);
        }
      }
    }
  };

  useEffect(() => {
    // orderPlaced();
  }, []);

  return (
    <DefaultLayout>
      <ModalContainer
        title="Order Success"
        modalOpen={isOrderPlaced}
        setModalOpen={setIsOrderPlaced}
        closeModal={() => setIsOrderPlaced(false)}
        width={40}
      >
        <div className=" flex flex-col px-[30px]">
          <div className="flex gap-[10px] flex-wrap max-w-[500px] mx-auto pt-[30px]">
            <p className="text-center text-[18px] text-gray-600 ">
              Hurray🎉, Your order has been placed successfully and your food is
              being prepared
            </p>
          </div>
          <div className="flex gap-[20px] mx-auto mt-[30px]">
            <button
              className="border-0 outline-0 b  rounded-[50px] bg-[var(--primary)] py-[10px] px-[30px] text-white font-600 w-[100%] mt-[10px] flex items-center gap-[10px] justify-center"
              onClick={() => {
                setIsOrderPlaced(false);
                navigate(ROOT);
              }}
            >
              Order Again
            </button>
          </div>
        </div>
      </ModalContainer>

      {/* Section 1 */}
      <div className="container mx-auto pt-[50px]">
        <div className="bg-[var(--secondary)] p-[35px] flex md:flex-row gap-[30px] flex-col rounded-[20px]">
          <div className="content md:flex-[0.5] flex-1 my-auto">
            <h1 className="font-semibold md:text-5xl text-2xl">
              It’s the Summer of (New Things You’ll) Love
            </h1>
            <h6 className="mt-[22px] font-semibold">
              It’s only May, and our summer plans are already poppin’ off.
            </h6>
            <p className="mt-[10px] text-[#636363]">
              Our new Build Your Curry is bringing the heat with our fiery Appetizers and Tandoori Dishes. Then, we
              take things up by cooling them down with the return of Mango Lassi and Mango Milkshake.
            </p>
            <button
              onClick={() => navigate("/product?buildCurry=true")}
              className="md:text-[20px] border-0 outline-0 mt-[20px] rounded-[100px] bg-[var(--primary)] md:py-[13px] py-[6px] md:px-[30px] px-[24px] text-white"
            >
              Build Curry Plate
            </button>
          </div>
          <div className="flex md:flex-[0.5] flex-1">
            <img src={Banner1} alt="" />
          </div>
        </div>
      </div>

      {/* Section 2 */}
      <div className="container mx-auto mt-[120px]">
        <div className="grid md:grid-cols-6 grid-cols-2 gap-[20px] justify-between">
          {[...SideCategory].map((item: any, i) => (
            <div
              className="flex items-center m-auto justify-center flex-col cursor-pointer"
              onClick={() => {
                if (item?.category_id === 1) {
                  navigate("/product?buildCurry=true");
                  return;
                }
                navigate("/product?category=" + item?.category_id);
              }}
              key={i}
            >
              <div className="rounded-[50%] mb-[-40px] z-[200] p-[10px] bg-[#fff]">
                <img src={item?.image} alt="" className="w-[120px]" />
              </div>
              <div
                className={`pb-[20px] md:w-[200px] w-[160px] h-[120px] px-[30px] rounded-[20px] pt-[60px] bg-[var(--secondary)] hover:text-[#ffff] flex items-center justify-center ${item?.featured
                    ? "!bg-[var(--brand-red)] text-white"
                    : "hover:bg-[var(--primary)]"
                  }`}
              >
                <p className="text-center">{item?.name}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Section 3 */}
      <div className="container mx-auto w-[90%] bg-homeImg1  bg-cover rounded-[20px] overflow-hidden flex justify-center items-center md:py-[120px] py-[60px]  mt-[120px]">
        <div className="content  flex items-center flex-col">
          <h2 className="text-white text-center font-semibold text-4xl">
            Build Your Own Curry
          </h2>
          <button
            className="border-0 outline-0 rounded-[100px] bg-[var(--primary)] py-[13px] px-[30px] text-white font-600 mt-[20px]"
            onClick={() => navigate("/product?buildCurry=true")}
          >
            Cook me a Curry
          </button>
        </div>
      </div>

      {/* Section 4 */}

      {/* <div className="container mx-auto flex flex-col items-cetner justify-center mt-[120px]">
        <h2 className="text-[40px] font-semibold text-center ">
          Curry Cook’s Menu
        </h2>
        <div className="mt-[30px]">
          <div className="grid grid-cols-4 gap-[30px]">
            <div className="p-[20px] flex flex-col rounded-[20px] bg-[var(--secondary)]">
              <h2 className="text-[24px] font-semibold ">Category</h2>
              <hr className="h-[2px] bg-[var(--neutral1)] my-[15px]" />

              {categoryData.map((item: any, index: number) => {
                if (index < 5)
                  return (
                    <>
                      <h6
                        className={
                          " text-[18px] font-[500] cursor-pointer " +
                          (selectedCategory?.category_id == item?.category_id
                            ? "text-[var(--primary)]"
                            : "")
                        }
                        onClick={() => setSelectedCategory(item)}
                      >
                        {item.category_name}
                      </h6>
                      <hr className="h-[2px] bg-[#ECEEF0] my-[15px]" />
                    </>
                  );
              })}

              <button
                className="border-0 outline-0 w-[100%] rounded-[100px] bg-[var(--primary)] py-[13px] px-[30px] text-white mt-[auto] font-600 "
                onClick={() => navigate("product")}
              >
                View all
              </button>
            </div>

            <div className="col-span-3 flex flex-col">
              <div className="flex ml-auto gap-[30px]">
                <div className="bg-[#fff] cursor-pointer border-[1px] border-[#D3D4D6] h-[50px] shadow-md flex items-center justify-center w-[50px] rounded-[50%]">
                  <HiChevronLeft size={40} />
                </div>
                <div className="bg-[var(--primary)] cursor-pointer h-[50px] shadow-md flex items-center justify-center w-[50px] rounded-[50%]">
                  <HiChevronRight color={"white"} size={40} />
                </div>
              </div>

              <div className="grid grid-cols-3  gap-[30px] mt-[auto]">
                {[
                  ...productData?.filter(
                    (item: any) =>
                      item.category_id == selectedCategory?.category_id
                  ),
                ].map((item, i) => {
                  if (i < 3)
                    return (
                      <div className="bg-[#fff] col-span-1 border-[1px] border-[#D3D4D6] flex flex-col items-center justify-center shadow-md rounded-[20px] p-[20px]">
                        <div className="rounded-[10px] w-[100%] overflow-hidden  flex h-[162px]">
                          <img
                            src={item?.product_photo}
                            alt=""
                            className="object-cover w-[100%]"
                          />
                        </div>
                        <h3 className="text-[20px] mt-[10px] font-semibold">
                          {item?.product_name}
                        </h3>
                        <p className="mt-[5px] text-[#636363] text-[14px] mb-[10px]">
                          {item?.product_desc}
                        </p>
                        <div className="mt-[auto] flex items-end gap-[10px] mb-[10px]">
                          <p className="text-[14px] font-[500] relative text-[#636363]">
                            ${item?.product_price}
                            <div className="h-[1px] bg-[#636363] absolute w-[100%] top-[50%]"></div>
                          </p>
                          <p className="text-[20px] text-[var(--primary)]">
                            $
                            {Number(
                              item?.product_price - item?.discount
                            )?.toFixed(2)}
                          </p>
                        </div>
                        <button
                          className="border-0 outline-0  rounded-[50px] bg-[var(--primary)] py-[10px] px-[30px] text-white font-600 w-[100%] mt-[10px]"
                          onClick={() => {
                            navigate("product?cartAdd=" + item?.product_id);
                          }}
                        >
                          Add to cart
                        </button>
                      </div>
                    );
                })}
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* Section 5 */}

      <div className="mt-[120px] flex md:flex-row flex-col px-5">
        <div className="bg-homeImg2  bg-cover bg-center bg-no-repeat  object-contain bg-norepeat flex-[0.5]"></div>
        <div className="md:flex-[0.5] flex-1 flinex flex-col justify-center items-center bg-[var(--secondary)] py-[30px]">
          <div className="mx-auto w-[fit-content]">
            <h2 className="text-[40px] font-[600] text-center">
              Essential Spices
            </h2>
            <div className="mt-[30px] md:w-[500px] w-[90%] mx-auto flex flex-col gap-[20px]">
              {[...EssentialSpecies].map((item: any, i) => (
                <div
                  className="p-[10px] bg-[#fff] rounded-[10px] flex gap-[15px]"
                  key={i}
                >
                  <div className="rounded-[10px] overflow-hidden min-w-[80px] h-[80px] flex">
                    <img src={item?.image} alt="" className="w-[100%]" />
                  </div>
                  <div>
                    <h2 className="text-[20px] font-semibold">{item?.title}</h2>
                    <p className="mt-[5px] text-[#636363] text-[12px]">
                      {item?.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Section 6 */}

      <div className="mt-[120px] container mx-auto">
        <h2 className="text-[40px] font-semibold text-center ">Recent Blogs</h2>
        <div className="mt-[30px] flex flex-col">
          <div className="grid md:grid-cols-3 grid-cols-1 gap-[30px]">
            {[...Array(3)].map((_, i) => (
              <div
                className="bg-[#fff] max-w-[400px] mx-auto col-span-1 border-[1px] border-[#D3D4D6] flex flex-col  justify-center shadow-md rounded-[20px] p-[20px]"
                key={i}
              >
                <div className="rounded-[10px] overflow-hidden flex h-[162px] w-[100%] ">
                  <img
                    src={product1}
                    alt=""
                    className="object-cover w-[100%]"
                  />
                </div>
                <p className="text-[12px] font-[500] mt-[10px] text-[#636363] text-start">
                  23 June, 2023
                </p>
                <h2 className="mt-[10px] text-[20px] font-[600]">
                  Et minima ea non occaecati quaerat.
                </h2>
                <p className="text-[14px] text-[#636363] mt-[5px]">
                  Numquam sint ut inventore sed. Enim fugit eos expedita dolor
                  id. Inventore itaque perspiciatis. Minus ea aliquam. Iure
                  optio quo.
                </p>
              </div>
            ))}
          </div>

          <button className="border-0 outline-0 mx-auto mt-[30px] rounded-[100px] bg-[var(--primary)] py-[13px] px-[30px] text-white font-600 ">
            Browse more
          </button>
        </div>
      </div>

      {/* Section 7 */}

      <div className="sm:mt-[120px] mt-[60px] container mx-auto flex md:flex-row flex-col rounded-[20px] overflow-hidden">
        <div className="md:w-[50%] w-[100%] bg-[var(--secondary)] p-[50px]">
          <h2 className="text-[40px] font-semibold">Get in Touch</h2>
          <form onSubmit={handleSubmitGetInTouch}>
            <div className="mt-[20px] flex flex-col gap-[20px]">
              <div className="flex flex-col ">
                <label className="text-[14px] font-[500]">Your Name*</label>
                <input
                  type="text"
                  className="h-[50px] rounded-[20px] mt-[5px] px-[20px] py-[13px] border border-gray-400"
                  placeholder="Your Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>

              <div className="flex flex-col ">
                <label className="text-[14px] font-[500]">Email ID*</label>
                <input
                  type="email"
                  className="h-[50px] rounded-[20px] mt-[5px] px-[20px] py-[13px] border border-gray-400"
                  placeholder="Your Email ID"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              <div className="flex flex-col ">
                <label className="text-[14px] font-[500]">Mobile Number*</label>
                <input
                  type="number"
                  className="h-[50px] rounded-[20px] mt-[5px] px-[20px] py-[13px] border border-gray-400"
                  placeholder="123-456-7890"
                  value={phone}
                  onChange={(e) => {
                    if (e.target.value.length <= 10) setPhone(e.target.value);
                  }}
                />
              </div>

              <div className="flex flex-col">
                <label className="text-[14px] font-[500]">Message</label>
                <textarea
                  className="rounded-[20px] mt-[5px] px-[20px] py-[13px] border border-gray-400"
                  rows={3}
                  placeholder="Your Message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </div>

              <button
                className="border-0 outline-0 w-[fit-content] rounded-[100px] bg-[var(--primary)] py-[13px] px-[30px] text-white font-600 "
                type="submit"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
        <div className="w-[50%] md:block hidden bg-homeImg3 bg-cover bg-center bg-no-repeat"></div>
      </div>

      {/* Section 8 */}

      <div className="mt-[120px]">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3172.3325395304414!2d-122.01116148467422!3d37.33463524513264!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808fb59127ce078f%3A0x18e1c3ce7becf1b!2sApple%20Park!5e0!3m2!1sen!2sin!4v1637309850935!5m2!1sen!2sin"
          width={"100%"}
          height="350"
          style={{ border: 0 }}
          allowFullScreen={true}
          loading="lazy"
          title="Map"
        ></iframe>
      </div>
    </DefaultLayout>
  );
};

export default Home;
