import React from "react";

import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/logo.png";
import { ShoppingCartOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import {
  setCartData,
  setIsCartOpen,
} from "../../redux/productCart/productCartSlice";

import { Button } from "antd";
import ModalContainer from "../ModalContainer/ModalContainer";
import IncompleteCurryModal from "../Modal/IncompleteCurryModal";

const Navbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isCartOpen = useSelector((state: RootState) => state.cart.isCartOpen);
  const handleCartOpen = () => {
    dispatch(setIsCartOpen(!isCartOpen));
  };

  const cartData = useSelector((state: RootState) => state.cart.cartData);
  const hasItemsInCart = cartData?.length > 0;


  const [showCartPopup, setShowCartPopup] = React.useState(false);

  return (
    <div className="bg-[#ffc215] sm:py-[15px] pt-[10px] sticky top-0">
      <div className="container mx-auto flex md:flex-row flex-col sm:gap-[30px] gap-[5px] justify-between items-center">
        <div className="flex flex-wrap gap-[20px]">
          <Link to="/">
            <img src={Logo} className="brand sm:w-[150px] w-[100px] object-contain sm:object-fill" alt="" />
          </Link>
        </div>

        <div className="flex gap-[18px] items-center justify-center">
          <div className="flex gap-[20px]">
            <Link to="/product?buildCurry=true">
              <p className="text-black text-[18px] font-medium cursor-pointer  hover:text-white">
                Build Curry Plate
              </p>
            </Link>

            <p
              className="text-black text-[18px] font-medium cursor-pointer  hover:text-white"
              onClick={() => {
                if (
                  cartData?.filter?.((item: any) => item?.category_id < 4)
                    ?.length > 0
                ) {
                  setShowCartPopup(true);
                } else {
                  navigate("/product");
                }
              }}
            >
              Order menu
            </p>
          </div>
          <div
            onClick={handleCartOpen}
            onKeyDown={handleCartOpen}
            className="product-cart__header--close"
          >
            <ShoppingCartOutlined className={`text-[20px] mb-4 ${hasItemsInCart ? 'text-red-500' : 'text-black'}`} />
          </div>
        </div>
      </div>
      <IncompleteCurryModal
        isOpen={showCartPopup}
        onClose={() => setShowCartPopup(false)}
        onSubmit={() => {
          dispatch(
            setCartData(cartData?.filter((item: any) => item?.category_id > 4))
          );
          navigate("/product");
          setShowCartPopup(false);
        }}
      />
    </div>
  );
};

export default Navbar;
